<template>
    <div>
        <el-form-item v-if="isAdmin" :prop="props[0]" label="公司分类">
            <el-select style="width:100%" v-model="dataObj[props[0]]"  placeholder="请选择公司的公司分类" size="mini"
                clearable :disabled="isDetail"
                @change="onChangeGroup">
                <el-option v-for="item in roleGroups" :key="item.name" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item :prop="props[1]" label="公司名称">
            <el-select style="width:100%" v-model="dataObj[props[1]]" placeholder="请选择一个公司" size="mini"
                filterable :clearable="!companyDisabled" :disabled="isDetail || !utils.checkNumberIsNotEmpty(dataObj[this.props[0]])"
                @change="onChangeCompany">
                <el-option v-for="company in companyList" :key="company.id" :label="company.companyName" :value="company.id"></el-option>
            </el-select>
        </el-form-item>
        <!-- <el-form-item v-if="showSite" :prop="props[2]" label="所属站点">
            <el-select style="width:100%" v-model="dataObj[props[2]]"  placeholder="请选择所属站点" size="mini"
                filterable clearable :disabled="readOnly || !utils.checkNumberIsNotEmpty(dataObj[this.props[1]])"
                @change="onChangeSite"
            >
                <el-option v-for="site in siteList" :key="site.id" :value="site.siteId" :label="site.siteName"></el-option>
            </el-select>
        </el-form-item> -->
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

import bizApi from "@/api/business";
// import siteApi from "@/api/site";

export default {
    computed: {
        ...mapGetters(['user'])
    },
    props: {
        props: {
            type: Array,
            default: [ "tenantId", "businessId" ]
        },
        businessId: {
            type: String,
            default: ""
        },
        dataObj: {
            type: Object,
            default: {}
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        isAdd: {
            type: Boolean,
            default: false
        },
        isDetail: {
            type: Boolean,
            default: false
        },
        companyDisabled: {
            type: Boolean,
            default: false
        },
        except: {
            type: Function,
            default: undefined
        }
    },
    computed: {
        ...mapGetters(['user'])
    },
    watch: {
        dataObj: function() {
            this.getDatas();
        },
    },
    data () {
        return {
            utils: $utils,
            roleGroups: [].concat($statics.ROLE_GROUP_LIST),
            companyList: [],
            companyMap: {},
            siteList: [],
            siteMap: {}
        }
    },
    created () {
        console.log("business-edit");
        // if (this.user.tenantId !== 0) {
        //     this.roleGroups.shift();
        // }
    },
    mounted () {
        if (this.isAdd) {
            this.getDatas();
        }
    },
    methods: {
        getDatas () {
            this.onSelectGroupCore();
            // if (this.showSite) {
            //     this.onSelectSiteCore();
            // }
        },
        onChangeGroup () {
            this.dataObj[this.props[1]] = "";
            // this.dataObj[this.props[2]] = "";
            this.onSelectGroupCore();
        },
        async onSelectGroupCore () {

            this.companyList = [];

            const params = {
                page:0,
                size: 99999999
            };

            if (this.isAdmin) {
                params.tenantId = this.dataObj[this.props[0]];
            } else {
                // 这里这么写的原因是用户编辑的时候，是要向下兼容
                // 编辑公司的时候，需要向上兼容
                let subsidiaryId = this.dataObj[this.props[2] || this.props[1]];
                subsidiaryId = subsidiaryId || this.dataObj[this.props[1]];
                if (!subsidiaryId) return;
                params.subsidiaryId = `,${subsidiaryId},`;
            }

            const res = await bizApi.list(params);

            
            const companyMap = {}, companyList = [];
            for (const company of res.data) {
                // 去除不符合条件的
                if (!(company.id.toString() === this.businessId.toString()) && !(this.except && this.except(company))) {
                    companyMap[company.id] = company;
                    companyList.push(company);
                }
            };

            this.companyList = companyList;
            this.companyMap = companyMap;
            const company = companyMap[this.dataObj[this.props[1]]];
            if (company) {
                this.dataObj[this.props[0]] = company.tenantId;
                this.$emit("select-company", company);
            } else {
                this.$emit("select-company", null);
            }
        },
        onChangeCompany () {
            const company = this.companyMap[this.dataObj[this.props[1]]];
            // this.dataObj[this.props[2]] = "";
            // this.onSelectSite();
            this.$emit("select-company", company);
            // if (this.showSite) {
            //     this.$emit("select-site", null);
            // }
        },
        // onSelectSite () {
        //     this.dataObj[this.props[2]] = "";
        //     this.onSelectSiteCore();
        // },
        // async onSelectSiteCore () {
        //     if (!this.showSite) return;
        //     this.siteList = [];
        //     const res = await siteApi.list({
        //     companyId: this.dataObj[this.props[1]]
        //     });
        //     this.siteList = res.data;

        //     const siteMap = {};
        //     for (const site of this.siteList) {
        //         siteMap[site.siteId] = site;
        //     }
        //     this.siteMap = siteMap;
        //     this.$emit("select-site", siteMap[this.dataObj[this.props[2]]]);
        // },
        // onChangeSite () {
        //     const site = this.siteMap[this.dataObj[this.props[2]]];
        //     if (site) {
        //         this.$emit("select-site", site);
        //     } else {
        //         this.$emit("select-site", null);
        //     }
        // }
    }
}
</script>