var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isAdmin
        ? _c(
            "el-form-item",
            { attrs: { prop: _vm.props[0], label: "公司分类" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: "请选择公司的公司分类",
                    size: "mini",
                    clearable: "",
                    disabled: _vm.isDetail,
                  },
                  on: { change: _vm.onChangeGroup },
                  model: {
                    value: _vm.dataObj[_vm.props[0]],
                    callback: function ($$v) {
                      _vm.$set(_vm.dataObj, _vm.props[0], $$v)
                    },
                    expression: "dataObj[props[0]]",
                  },
                },
                _vm._l(_vm.roleGroups, function (item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { prop: _vm.props[1], label: "公司名称" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: {
                placeholder: "请选择一个公司",
                size: "mini",
                filterable: "",
                clearable: !_vm.companyDisabled,
                disabled:
                  _vm.isDetail ||
                  !_vm.utils.checkNumberIsNotEmpty(_vm.dataObj[this.props[0]]),
              },
              on: { change: _vm.onChangeCompany },
              model: {
                value: _vm.dataObj[_vm.props[1]],
                callback: function ($$v) {
                  _vm.$set(_vm.dataObj, _vm.props[1], $$v)
                },
                expression: "dataObj[props[1]]",
              },
            },
            _vm._l(_vm.companyList, function (company) {
              return _c("el-option", {
                key: company.id,
                attrs: { label: company.companyName, value: company.id },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }